/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, useEffect} from "react";
import { useNavigate } from "react-router-dom";
import Header from "./header";
// import Footer from "./footer";
import userService from "../../services/user.service";
import Deposit from "./deposit";
import Withdraw from "./withdraw";
// import Friend from "./friend";
import Promotion from "./promotion";
import History from "./history";
import Profile from "./profile";
import PlayGame from "./playGame";
import ChangePassword from "./changePassword";
import ReturnCredit from "./returnCredit";
import Betflix from "./betflix";
import configData from "../../config.json";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import gameService from "../../services/game.service";
import helpersService from "../../services/helpers.service";
import settingService from "../../services/setting.service";
import Huay14 from "./huay14";
const IMAGE_URL = configData.REACT_APP_IMAGE_URL;
const API_URL = configData.REACT_APP_API_URL;
const MySwal = withReactContent(Swal)
function Member(){
    const [loading, setLoading] = useState(false)
    const [statePage, setStatePage] = useState('home')
    const [wallet, setWallet] = useState(0)
    const [creditBetflix, setCreditBetflix] = useState(0)
    const [creditSbobet, setCreditSbobet] = useState(0)
    const [creditUfabet, setCreditUfabet] = useState(0)
    const [creditHuay14, setCreditHuay14] = useState(0)
    const [creditSa, setCreditSa] = useState(0)
    const [user, setUser] = useState(null)
    const [spin, setSpin] = useState(false)
    const [setting, setSetting] = useState(null)
    const [statusHuay14, setStatusHuay14] = useState(false)
    const [statusBetflix, setStatusBetflix] = useState(false)
    const [statusSbobet, setStatusSbobet] = useState(false)
    const [statusSagame, setStatusSagame] = useState(false)
    const [statusUfa, setStatusUfa] = useState(false)

    const navigate = useNavigate();
    const paramCredit = {creditBetflix, setCreditBetflix, creditSbobet, setCreditSbobet, creditUfabet, setCreditUfabet, creditSa, setCreditSa, creditHuay14, setCreditHuay14}
    const statusGame = {statusHuay14, statusBetflix, statusSbobet, statusSagame, statusUfa}
   
    useEffect(() => {
        const getUserProfile = async () => {
            try {
                const {data: userProfile} = await userService.getProfile()
                if (userProfile.code === 200) {
                    const user1 = userProfile.data;
                    setUser(user1);
                    setWallet(user1.walletDisplay);
                    localStorage.setItem("noone-wallet-user", JSON.stringify(user1));
                    
                    
                }else{
                    localStorage.clear();
                    navigate('/auth/login');
    
                }
                 
            } catch (error) {
                console.error(error)
                localStorage.clear();
                navigate('/auth/login');
            }
            
        }
        if(user === null){
            getUserProfile()
        }
        const getSetting = async () => {
            try {
                const {data: dataSetting} = await settingService.get();
                setSetting(dataSetting.data);
            }catch (error) {
                console.error(error)
                localStorage.clear();
                navigate('/auth/login');
            }
        }
        if(setting === null){
            getSetting();
        }
        const getGame = async () => {
            const {data: dataGetGame} = await gameService.get();
            if (dataGetGame.code === 200) {
                if(dataGetGame.data.length > 0){
                    for(var i=0; i < dataGetGame.data.length; i++){
                        if(dataGetGame.data[i].id === 1){
                            setStatusBetflix(true);
                            setStatusSagame(true);
                        }else if(dataGetGame.data[i].id === 2){
                            setStatusSbobet(true);
                        }else if(dataGetGame.data[i].id === 3){
                            setStatusUfa(true);
                        }else if(dataGetGame.data[i].id === 4){

                        }else if(dataGetGame.data[i].id === 5){
                            setStatusHuay14(true);

                        }else if(dataGetGame.data[i].id === 6){
                        }
                    }
                }
            }
        }
        getGame();
    }, []);

    const getWallet = async () => {
        setSpin(true);
        try {
            const {data: userProfile} = await userService.getProfile()
            if (userProfile.code === 200) {
                setSpin(false);
                const user1 = userProfile.data;
                setUser(user1);
                setWallet(user1.walletDisplay);
                localStorage.setItem("noone-wallet-user", JSON.stringify(user1));
            }else{
                localStorage.clear();
                navigate('/auth/login');
            }
            
        }catch (error) {
            console.error(error)
            localStorage.clear();
            navigate('/auth/login');
        }
    }

    const checkout = async () => {
        MySwal.fire({
            title: 'โหลด...',
            allowOutsideClick: false,
            didOpen: () => {
                MySwal.showLoading()
            },
        });
        
        try {
            const {data: dataCheckout} = await gameService.checkout();
            if(dataCheckout.code === 200){
                if(dataCheckout.data.length > 0){
                    setWallet(dataCheckout.data[0].wallet);
                    for(var i=0; i < dataCheckout.data.length; i++){
                        if(dataCheckout.data[i].gameId === 1){
                            setCreditBetflix(dataCheckout.data[i].credit);
                        }else if(dataCheckout.data[i].gameId === 2){
                            setCreditSbobet(dataCheckout.data[i].credit);
                        }else if(dataCheckout.data[i].gameId === 3){
                            setCreditUfabet(dataCheckout.data[i].credit);
                        }else if(dataCheckout.data[i].gameId === 4){

                        }else if(dataCheckout.data[i].gameId === 5){
                            setCreditHuay14(dataCheckout.data[i].credit);

                        }else if(dataCheckout.data[i].gameId === 6){
                            setCreditSa(dataCheckout.data[i].credit);
                        }
                    }
                }
                MySwal.close();
            }else{
                MySwal.fire({
                icon: 'error',               
                title: <p>เกิดข้อผิดพลาด</p>,
                text: 'กรุณาติดต่อฝ่ายบริการ',
                })
            }
            
        }catch (error) {
            console.error(error)
            localStorage.clear();
            navigate('/auth/login');
        }
    }
    

    setTimeout(() => {
        setLoading(false);
    }, 700);

    const playGames = async (id,huay) => {
        MySwal.fire({
            title: 'โหลด...',
            allowOutsideClick: false,
            didOpen: () => {
                MySwal.showLoading()
            },
        });
        try {
            var id1 = id;
            if(id === 6){
                id1 = 1;
            }
            const {data: dataBeforePlay} = await gameService.beforePlay(id1);
            if(dataBeforePlay.code === 200){
                setWallet(dataBeforePlay.data.wallet);
                MySwal.close();

                if(id === 1){
                    setStatePage('betflix')
                    paramCredit.setCreditBetflix(dataBeforePlay.data.credit)
                }
                if(id === 2){
                    
                    MySwal.fire({
                        icon: 'success',               
                        title: <p>ย้ายเครดิตสำเร็จ</p>,
                        showConfirmButton: true,
                        confirmButtonText: 'เข้าเกม',
                    }).then(async function(result){
                        window.open(API_URL+'/game/playGameSbobet/'+user.id);
                        MySwal.close();
                        
                    });
                }
                if(id === 3){
                    MySwal.fire({
                        icon: 'success',               
                        title: <p>ย้ายเครดิตสำเร็จ</p>,
                        showConfirmButton: true,
                        confirmButtonText: 'เข้าเกม',
                    }).then(async function(result){
                        window.open(API_URL+'/game/playGameUfabet/'+user.id);
                        MySwal.close();
                    });
                    
                }
                if(id === 5){
                    // setStatePage('huay14')
                    // paramCredit.setCreditHuay14(dataBeforePlay.data.credit)
                    MySwal.fire({
                        icon: 'success',               
                        title: <p>ย้ายเครดิตสำเร็จ</p>,
                        showConfirmButton: true,
                        confirmButtonText: 'เข้าเกม',
                    }).then(async function(result){
                        
                        window.open(API_URL+'/game/playGameHuay14/'+huay+'/'+user.id);
                        
                    });
                }
                if(id === 6){
                    MySwal.fire({
                        icon: 'success',               
                        title: <p>ย้ายเครดิตสำเร็จ</p>,
                        showConfirmButton: true,
                        confirmButtonText: 'เข้าเกม',
                    }).then(async function(result){
                        // const {data: dataPlayGame} = await gameService.playGameBetflix('sa', 3);
                        // if(dataPlayGame.code === 200){
                        //     window.open(dataPlayGame.data.launch_url);
                        //     MySwal.close();
                        // }
                        window.open(API_URL+'/game/playGameBetflixRedirect/'+user.id+'/sa/3');
                        
                    });
                   
                }
            }else{
                MySwal.fire({
                    icon: 'error',               
                    title: <p>ไม่สำเร็จ</p>,
                    text: dataBeforePlay.message,
                  })
            }
        }catch (error) {
            console.error(error)
            localStorage.clear();
            navigate('/auth/login');
        }
        
    }
    return (
       <>
       {loading && (
            <div className="preload">
                <img src={IMAGE_URL+"logo.webp"} className="img-fluid" alt="logo" width="50%" />
            </div>
        )}
        <div className="containall">
            <div data-aos="fade-right" className="contentmain boxcolor aos-init aos-animate">
                <Header />
                <div className="containmoney">
                    <table width="100%">
                        <tbody><tr>
                            <td width="50%">
                                <i  id="opensection01"className="fal fa-wallet"></i>
                            </td>
                            <td width="50%" className="text-right">
                                <span style={{fontSize: "16px"}} className="headerprocess"><i className="fad fa-coin"></i> เครดิตกระเป๋าหลัก</span>
                                <br/>
                                <span id="mainCredit" className="headerprocess" style={{fontSize: "20px", color: "#ffda6a"}}> {helpersService.encodeMoneyFormat(wallet)}<span style={{fontSize: "15px"}}> บาท</span>&nbsp;<i className={spin ? "fas fa-sync fa-spin" : "fas fa-sync "} onClick={() => {getWallet()}} style={{cursor: 'pointer',color: '#fff',fontSize: "20px"}} id="refreshCreditBetflix"></i></span>
                            </td>
                        </tr>
                        </tbody></table>
                    <hr className="hrmoney"/>
                    {/* <div className="my-2 pl-1">
                        <i className="fad fa-gift" style={{color: "#ffda6a"}}></i>
                        <span>โปรโมชั่น : -</span>
                    </div> */}
                    <table width="100%">
                        <tbody>
                            <tr>
                                {/* <td width="50%" style={{textAlign: "center"}}>
                                    <button className="btnfriend mcolor" onClick={()=>{setStatePage('friend');}}>แนะนำเพื่อน <i className="fal fa-users-medical"></i></button>
                                </td> */}
                                <td width="100%" style={{textAlign: "center"}}>

                                    <button id="btnCashout" onClick={() => {checkout()}} className="btncommis bkcolor" data-toggle="tooltip" data-placement="top" title="text.wallet.cashout_desc"><i className="far fa-sort-alt"></i> ดึงเครดิตกลับกระเป๋าหลัก</button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div id="section01" className="tabcontent mainsection" style={{display: statePage === "home" ? "block" : "none"}}>
                    <div data-aos="fade-right" className="contentmain boxcolor aos-init aos-animate">
                        <div className="menucontain">
                            <table style={{width: "100%"}}>
                                <tbody>
                                <tr>
                                    <td className="tdgridicon"><span onClick={()=>{setStatePage('deposit');}}><i className="fal fa-plus tablinks"></i><br/>ฝากเงิน</span></td>
                                    <td className="tdgridicon"><span onClick={()=>{setStatePage('withdraw');}}><i className="fal fa-minus tablinks"></i><br/>ถอนเงิน</span></td>
                                    <td className="tdgridicon"><span onClick={()=>{setStatePage('promotion');}}><i className="fal fa-gift button--resize tablinks" id=""></i><br/>โปรโมชั่น</span></td>
                                    <td className="tdgridicon"><span onClick={()=>{setStatePage('history');}}><i className="fal fa-history tablinks" id=""></i><br/>ประวัติ</span></td>
                                </tr>
                                <tr>
                                    <td className="tdgridicon"><span onClick={()=>{setStatePage('profile');}}><i className="fal fa-user-alt tablinks" id=""></i><br/>บัญชี</span></td>
                                    <td className="tdgridicon"><span onClick={()=>{setStatePage('playGame');}} className="joystick-x"><i className="fal fa-gamepad-alt tablinks" id=""></i><br/>เข้าเล่นเกม</span></td>
                                    <td className="tdgridicon"><span onClick={()=>{setStatePage('returnCredit');}}><i className="fal fa-badge-dollar tablinks" id=""></i><br/>คืนยอดเสีย</span></td>
                                    <td className="tdgridicon"><span onClick={() => { window.open(setting && setting.SETTING_LINE); } }><i className="fal fa-comments-alt tablinks"></i><br/>ติดต่อเรา</span></td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <br></br>
                        {/* <div className="headerprocess">เข้าเล่นเกม</div> */}
                        <div className="containprocess contain-game pt-2">
                            {/* <table style={{width: "100%"}}>
                                <tbody>
                                    <tr>
                                        <td >
                                            <div id="huay14_thai" className="game-card mb-2 text-center containgame" style={{cursor: 'pointer',padding:"10px",display: statusHuay14 ? "flex" : "none"}}>
                                                <img src={IMAGE_URL+'Thai.gif?v=0.1'} onClick={()=>{playGames(5,'lotto')}} alt="" className="img-fluid" style={{borderRadius:'20px'}}  />
                                            </div>
                                        </td>
                                        <td >
                                            <div id="huay14_lao" className="game-card mb-2 text-center containgame" style={{cursor: 'pointer',padding:"10px",display: statusHuay14 ? "flex" : "none"}}>
                                                <img src={IMAGE_URL+'Lao.gif?v=0.1'} onClick={()=>{playGames(5,'lotto-laos')}} alt="" className="img-fluid" style={{borderRadius:'20px'}} />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td >
                                            <div id="huay14_hanoi" className="game-card mb-2 text-center containgame" style={{cursor: 'pointer',padding:"10px",display: statusHuay14 ? "flex" : "none"}}>
                                                <img src={IMAGE_URL+'Hanoi.gif?v=0.1'} onClick={()=>{playGames(5,'lotto-hanoi')}} alt="" className="img-fluid" style={{borderRadius:'20px'}} />
                                            </div>
                                        </td>
                                        <td >
                                            <div id="huay14_stock_thai" className="game-card mb-2 text-center containgame" style={{cursor: 'pointer',padding:"10px",display: statusHuay14 ? "flex" : "none"}}>
                                                <img src={IMAGE_URL+'Thai_Stock.gif?v=0.1'} onClick={()=>{playGames(5,'stock')}} alt="" className="img-fluid" style={{borderRadius:'20px'}} />
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table> */}
                            {/* <div id="bet_filx" className="game-card mb-2 text-center containgame" style={{cursor: 'pointer',display: statusHuay14 ? "flex" : "none"}}>
                                <img src={IMAGE_URL+'huay14.png'} onClick={()=>{playGames(5)}} alt="" className="img-fluid"  width="607" style={{height: '185px'}}/>
                            </div> */}
                            <div id="bet_filx" className="game-card mb-2 text-center containgame" style={{cursor: 'pointer',display: statusBetflix ? "flex" : "none"}}>
                                <img src={IMAGE_URL+'BETFILX.webp'} onClick={()=>{playGames(1,'')}} alt="" className="img-fluid"  width="607" style={{height: '185px'}}/>
                            </div>
                            <div id="sbo_bet"  className="game-card mb-2 text-center containgame" style={{cursor: 'pointer',display: statusSbobet ? "flex" : "none"}}>
                                <img src={IMAGE_URL+'SBO.jpeg'} onClick={()=>{playGames(2,'')}} alt="" className="img-fluid" width="607" style={{height: '185px'}}/>
                            </div>
                            <div id="sa_game" className="game-card mb-2 text-center containgame" style={{cursor: 'pointer',display: statusSagame ? "flex" : "none"}}>
                                <img src={IMAGE_URL+'SAGaming.gif'} onClick={()=>{playGames(6,'')}} alt="" className="img-fluid" width="607" style={{height: '185px'}}/>
                            </div>
                            <div className="game-card mb-2 text-center containgame" style={{cursor: 'pointer',display: statusUfa ? "flex" : "none"}}>
                                <img src={IMAGE_URL+'UFA.webp'} onClick={()=>{playGames(3,'')}} alt="" className="img-fluid" width="607" style={{height: '185px'}}/>
                            </div>
                        </div>
                    </div> 
                </div> 
                
                <div className="backtohome button-resize-1" id="containbacktohome" style={{display: statePage !== "home" ? "flex" : "none"}} >
                    <span id="backhome" onClick={()=>{setStatePage('home');}}>
                        <i className="fas fa-home-lg-alt homebtn"></i>
                        <i className="fal fa-long-arrow-left font-weight-bold"></i> กลับสู่หน้าหลัก 
                    </span> &nbsp;
                     <span id="backtohistory" onClick={()=>{setStatePage('playGame');}} style={{cursor: "pointer", display: statePage === "betflix" || statePage === "betflix-sub" || statePage === "huay14" ? "block" : "none"}}>
                        <i className="fas fa-history homebtn"></i>
                        <i className="fal fa-long-arrow-left font-weight-bold"></i> กลับสู่หน้าเข้าเกมส์  
                    </span> &nbsp;
                    <span id="backtoplaygame" onClick={()=>{setStatePage('betflix');}} style={{cursor: "pointer", display: statePage === "betflix-sub" ? "block" : "none"}}>
                        <i className="fas fa-history homebtn"></i>
                        <i className="fal fa-long-arrow-left font-weight-bold"></i> Betflix
                    </span> &nbsp;
                   {/* <span id="backtoplaygame" style={{cursor: "pointer", display: "none"}}>
                        <i className="fas fa-history homebtn"></i>
                        <i className="fal fa-long-arrow-left font-weight-bold"></i> BACK TO GAME 
                    </span> &nbsp;
                    <span id="backtobetflix" style={{cursor: "pointer", display: "none"}}>
                        <i className="fas fa-history homebtn"></i>
                        <i className="fal fa-long-arrow-left font-weight-bold"></i> Betflix
                    </span> */}
                </div>
                
                <div id="deposit" className="tabcontent" style={{display: statePage === "deposit" ? "block" : "none"}}>
                    <Deposit />
                </div>
                <div id="withdraw" className="tabcontent" style={{display: statePage === "withdraw" ? "block" : "none"}}>
                    <Withdraw wallet={wallet} setting={setting} />
                </div>
                {/* <div id="friend" className="tabcontent" style={{display: statePage === "friend" ? "block" : "none"}}>
                    <Friend paramCredit={paramCredit}/>
                </div>  */}
                <div id="promotion" className="tabcontent" style={{display: statePage === "promotion" ? "block" : "none"}}>
                    <Promotion />
                </div> 
                <div id="history" className="tabcontent" style={{display: statePage === "history" ? "block" : "none"}}>
                    <History />
                </div> 
                <div id="account" className="tabcontent" style={{display: statePage === "profile" ? "block" : "none"}}>
                    <Profile setStatePage={setStatePage}/>
                </div>
                <div id="playgame" className="tabcontent" style={{display: statePage === "playGame" ? "block" : "none"}}>
                    <PlayGame setStatePage={setStatePage} setWallet={setWallet} paramCredit={paramCredit} user={user} statusGame={statusGame}/>
                </div>
                <div id="askmebet" className="tabcontent">
                </div>
                <div id="betfilx" className="tabcontent" style={{display: statePage === "betflix" || statePage === "betflix-sub" ? "block" : "none"}}>
                    <Betflix creditBetflix={creditBetflix} setCreditBetflix={setCreditBetflix} setStatePage={setStatePage} statePage={statePage} />
                </div>
                <div id="huay14" className="tabcontent" style={{display: statePage === "huay14" ? "block" : "none"}}>
                    <Huay14 creditHuay14={creditHuay14} setCreditHuay14={setCreditHuay14} setStatePage={setStatePage} statePage={statePage} />
                </div>
                <div id="ufabet" className="tabcontent" style={{display: statePage === "ufabet" ? "block" : "none"}}>
                    
                </div>
                <div id="balance-return" className="tabcontent" style={{display: statePage === "returnCredit" ? "block" : "none"}}>
                    <ReturnCredit />
                </div> 
                <div id="password" className="tabcontent" style={{display: statePage === "changePassword" ? "block" : "none"}}>
                   <ChangePassword />
                </div> 
            </div>
        </div>
        <div id="footer-wrapper">
            <div className="shortcut-wrapper">
                <div className="shortcut-list">
                    <div>
                        <a href="#" onClick={()=>{setStatePage('promotion');}}>
                            <img src={IMAGE_URL+"ic-promotion.png"} className="img-fluid" alt=""/>
                            <div className="title">โปรโมชั่น</div>
                        </a>
                    </div>
                    <div>
                        <a href="#" onClick={()=>{setStatePage('deposit');}}>
                            <img src={IMAGE_URL+"ic-deposit.png"} className="img-fluid" alt=""/>
                            <div className="title">ฝากเงิน</div>
                        </a>
                    </div>
                    <div className="open-game-link">
                        <a href="#" onClick={()=>{setStatePage('home');}}>
                            <img src={IMAGE_URL+"logo.webp?v=0.1"} className="img-fluid" style={{borderRadius: "100px"}} alt=""/>
                        </a>
                    </div>
                    <div>
                        <a href="#" onClick={()=>{setStatePage('withdraw');}}>
                            <img src={IMAGE_URL+"ic-withdraw.png"} className="img-fluid" alt=""/>
                            <div className="title">ถอนเงิน</div>
                        </a>
                    </div>
                    <div>
                        <a href={setting && setting.SETTING_LINE} target="_blank" rel="noreferrer" >
                            <img src={IMAGE_URL+"ic-line.png"} className="img-fluid" alt="" />
                            <div className="title">ติดต่อเรา</div>
                        </a>
                    </div>
                </div>
            </div>
        </div>
       </>
    )
}

export default Member;